<template>
  <div>
    <v-card flat rounded="0">
      <v-container>
        <div class="d-flex">
          <v-btn @click="$router.go(-1)" class="my-auto" icon>
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
          <div class="my-auto ml-2">
            Confirm Details
          </div>
        </div>
      </v-container>
    </v-card>

    <v-container>
      <v-container class="ma-0 pa-0 my-2">
        <v-row class="ma-0 pa-0">
          <v-col align="left" class="ma-0 pa-0">
            <p class="header-l ma-0 pa-0">Summary</p>
          </v-col>
        </v-row>
      </v-container>
      <v-card class="pa-4">
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Vehicle Name</p>
            </v-col>
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0">
                {{ selectionData.model || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">First Name</p>
            </v-col>
            <v-col class="body-2 ma-0 pa-0">
              <!-- <v-text-field
              v-model="prefilledData.firstName"
              append-icon="mdi-square-edit-outline"
              class="body-2 ma-0 pa-0"
              :rules="firstname"
              dense
              full-width
              outlined
            /> -->
              {{ prefilledData.firstName }}
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Last Name</p>
            </v-col>
            <v-col class="ma-0 pa-0">
              <!-- <v-text-field
              v-model="prefilledData.lastName"
              append-icon="mdi-square-edit-outline"
              class="body-2 ma-0 pa-0"
              :rules="lastname"
              dense
              outlined
            /> -->
              <p v-if="prefilledData" class="body-2 ma-0 pa-0">
                {{ prefilledData.lastName }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Gender</p>
            </v-col>
            <v-col class="ma-0 pa-0">
              <!-- <v-select
              v-model="prefilledData.gender"
              :items="genderDataItems"
              append-icon="mdi-square-edit-outline"
              class="body-2 ma-0 pa-0"
              dense
              outlined
            ></v-select> -->
              <p v-if="prefilledData" class="body-2 ma-0 pa-0">
                {{ prefilledData.gender }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">D.O.B</p>
            </v-col>
            <v-col class="ma-0 pa-0" v-if="prefilledData">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.dateOfBirth || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Marital status</p>
            </v-col>
            <v-col class="ma-0 pa-0" v-if="prefilledData">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.maritalStatus || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Mobile</p>
            </v-col>
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.mobileNumber || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Email</p>
            </v-col>
            <v-col class="ma-0 pa-0" cols="6">
              <!-- <v-text-field
              v-model="prefilledData.emailId"
              append-icon="mdi-square-edit-outline"
              class="body-2 ma-0 pa-0"
              :rules="emailRules"
              dense
              outlined
            /> -->
              <p v-if="prefilledData" class="body-2 ma-0 pa-0">
                {{ prefilledData.emailId }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Employment Type</p>
            </v-col>
            <v-col class="ma-0 pa-0" v-if="prefilledData.employmentInfo">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.employmentInfo[0].employmentType || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Employer Name</p>
            </v-col>
            <v-col class="ma-0 pa-0" v-if="prefilledData.employmentInfo">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.employmentInfo[0].employerName || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Annual Income</p>
            </v-col>
            <v-col class="ma-0 pa-0">
              <!-- <v-text-field
              v-model="prefilledData.employmentInfo[0].annualGrossSalary"
              append-icon="mdi-square-edit-outline"
              class="body-2 ma-0 pa-0"
              dense
              outlined
              prepend-inner-icon="mdi-currency-inr"
            /> -->
              <p v-if="prefilledData" class="body-2 ma-0 pa-0">
                {{ prefilledData.employmentInfo[0].annualGrossSalary }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Document Proof</p>
            </v-col>
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.identifier[0].type || "-" }} -
                {{ prefilledData.identifier[0].value || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Address Type</p>
            </v-col>
            <v-col class="ma-0 pa-0" v-if="prefilledData.address">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.address[0].addressType || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Residence Type</p>
            </v-col>
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0">
                {{
                  findPropertyStatus(prefilledData.address[0].propertyStatus)
                    .state || "-"
                }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Address</p>
            </v-col>
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.address[0].addressLine1 || "-" }}
                {{ prefilledData.address[0].addressLine2 || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pa-0 my-2">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0 font-weight-bold">Pincode</p>
            </v-col>
            <v-col class="ma-0 pa-0" cols="6">
              <p class="body-2 ma-0 pa-0">
                {{ prefilledData.address[0].pincode || "-" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-container class="ma-0 pa-0 my-2">
        <!-- <v-row class="ma-0 pa-0">
        <v-col cols="1" class="ma-0 pa-0">
          <v-checkbox v-model="enabled"> </v-checkbox>
        </v-col>
        <v-col class="ma-0 pt-5">
          <p class="ma-0 pa-0 caption">
            I agree to
            <a
              class="text-decoration-none font-italic"
              target="_blank"
              href="https://on-track.in/terms-of-use/"
              >Terms and Conditions</a
            >
          </p>
        </v-col>
      </v-row> -->
        <v-container class="px-0" fluid>
          <!-- <v-checkbox
          v-model="enabled"
        > 
        <template v-slot:label>
        I agree to <v-btn text  color="primary">Terms and Conditions</v-btn>
        </template>
        </v-checkbox> -->
          <label class="container">
            <input v-model="enabled" type="checkbox" />
          </label>
          I agree to
          <span @click="tncmodel = true" style="cursor:pointer" class="color-p"
            >Terms and Conditions</span
          >
        </v-container>
      </v-container>

      <!--    button container-->
      <v-container class="ma-0 pa-4">
        <v-row align="center" justify="space-around">
          <v-btn
            :disabled="!isButtonEnabled"
            block
            color="primary"
            depressed
            height="60"
            large
            tile
            @click="applyLoan"
          >
            Check Loan Eligibility
            <v-icon class="my-0 py-0">fal fa-angle-right</v-icon>
          </v-btn>
          <div class="my-4 text-center">
            <p class="ma-0 pa-0 caption">Go back to edit details.</p>
            <v-btn
              color="primary"
              @click="$router.push('/finance/pincode')"
              small
              text
              >Edit</v-btn
            >
          </div>
        </v-row>
      </v-container>
    </v-container>
    <v-overlay :value="isLoading" class="text-center">
      <v-progress-circular indeterminate color="#4F43D8" size="64" />
      <p>Please Wait..</p>
    </v-overlay>
    <v-dialog v-model="openTimerModel" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Application in progress...</span>
        </v-card-title>
        <v-card-text>
          <p>Your application may take upto {{ countDownTime }} seconds.</p>
          {{ displayText }}
          <v-progress-linear
            class="mt-3"
            :value="progressValue"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="500" v-model="tncmodel">
      <v-card>
        <tandc></tandc>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="tncmodel = false">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import tandc from "../views/TermsConditions.vue";
export default {
  data: () => ({
    tncmodel: false,
    displayText: "Application received by the banker.",
    progressValue: 5,
    formAllClear: false,
    enabled: false,
    items: [
      {
        src:
          "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/fc7fc50a-60a6-4d32-a8b4-fbdeac8ea3f7.png",
      },
      {
        src:
          "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/fc7fc50a-60a6-4d32-a8b4-fbdeac8ea3f7.png",
      },
      {
        src:
          "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/fc7fc50a-60a6-4d32-a8b4-fbdeac8ea3f7.png",
      },
      {
        src:
          "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/fc7fc50a-60a6-4d32-a8b4-fbdeac8ea3f7.png",
      },
    ],
    prefilledData: null,
    selectionData: null,
    genderDataItems: ["Male", "Female"],
    isLoading: null,
    firstname: [
      (value) => !!value || "First Name is required.",
      (value) =>
        (value && value.length > 1) || "Your name does not look right!",
      (value) => (value && value.length < 30) || "That is a very long name!",
      (value) => /^[a-zA-Z ]+$/.test(value) || "Nope! please type a valid name",
    ],
    lastname: [
      (value) => !!value || "Last Name is required.",
      // value => (value && value.length > 1) || 'Your name does not look right!',
      (value) => (value && value.length < 30) || "That is a very long name!",
      (value) => /^[a-zA-Z ]+$/.test(value) || "Nope! please type a valid name",
    ],
    income: [
      (value) => !!value || "Income is required.",
      (value) => /^[1-9,][0-9,]+$/.test(value) || "Invalid Value",
      (value) => (value && value.length > 5) || "Income too less",
      (value) =>
        (value && value.length <= 12) || "Whoa! lets be realistic here",
    ],
    emailRules: [
      (value) => !!value || "E-mail is required",
      (value) => /.+@.+\..+/.test(value) || "This E-mail is not valid",
    ],
    propertyStatusList: [
      {
        state: "Own house",
        abbr: "O",
      },
      {
        state: "Company Provided",
        abbr: "C",
      },
      {
        state: "Mortgaged",
        abbr: "M",
      },
      {
        state: "Relatives House",
        abbr: "H",
      },
      {
        state: "Rented",
        abbr: "R",
      },
    ],
    openTimerModel: false,
    interval: null,
    intervalChangeText: null,
    timer: null,
    message: [
      "Application on process.",
      "Waiting for Confirmation",
      "Please hold on we're working",
      "Internal Checks in Process",
      "Checking Bike Availability",
      "Verifying your personal details.",
      "Final Server Call",
    ],
    counter: 0,
    countDownTime: 60,
    applicationId: null,
    time: 1,
  }),
  components: {
    tandc,
  },
  created() {
    this.selectionData = JSON.parse(
      localStorage.getItem("selectionData")
    ).vehicle;
    this.prefilledData = JSON.parse(localStorage.getItem("rawForm"));
  },
  mounted() {},
  methods: {
    triggerTimer() {
      this.interval = window.setInterval(() => {
        this.checkStatus();
      }, 5000);
      this.intervalChangeText = window.setInterval(() => {
        this.changeText();
      }, 5000);
      this.timer = window.setInterval(() => {
        this.changeCountdown();
      }, 1000);
    },
    clearTimer() {
      window.clearInterval(this.interval);
      this.interval = null;
      window.clearInterval(this.intervalChangeText);
      this.intervalChangeText = null;
      window.clearInterval(this.timer);
      this.timer = null;
    },
    async checkStatus() {
      this.time++;
      var response = await this.$store.dispatch("checkLoanStatus", {
        applicationId: this.applicationId,
      });
      if (
        response.data.loanDetails[0].subStatus == "DDE Pending" ||
        response.data.loanDetails[0].subStatus == "QDE Pending"
      ) {
        try {
          await this.clearTimer();
        } finally {
          await this.$store.dispatch("updatePendingLoan", {
            applicationId: this.applicationId,
          });
          this.$router.push(`success/${this.applicationId}`);
        }
      } else if (
        response.data.loanDetails[0].subStatus.subStatus == "Scorecard Approved"
      ) {
        try {
          await this.clearTimer();
        } finally {
          this.$router.push(`success/${this.applicationId}`);
        }
      } else if (response.data.loanDetails[0].subStatus == "Posidex Reject") {
        try {
          await this.clearTimer();
        } finally {
          this.$router.push(`failure`);
        }
      } else if (this.time > 13) {
        try {
          await this.clearTimer();
        } finally {
          this.$router.push(`failure`);
        }
      }
    },
    changeText() {
      this.displayText = this.message[this.counter];
      this.counter++;
      this.progressValue + 5 < 100
        ? (this.progressValue += 5)
        : (this.progressValue = 100);
      this.counter >= this.message.length
        ? (this.counter = 0)
        : window.clearInterval(this.intervalChangeText);
    },
    changeCountdown() {
      this.countDownTime == 0
        ? window.clearInterval(this.timer)
        : this.countDownTime--;
    },
    convert() {
      window.gtag_report_conversion();
      this.$gtag.event("conversion", {
        send_to: "G-3V8TD767PC",
        key: "loan_applied_idfc",
      });
    },
    applyLoan() {
      this.isLoading = true;
      var eventName = this.prefilledData.source
        ? "create-loan-initiated-" + this.prefilledData.source
        : "create-loan-initiated-organic";
      this.$gtag.event(eventName, {
        event_category: "click-event",
        event_label: "create loan initiated",
        value: 1,
      });
      window.fbq("track", eventName);
      this.$api.HTTP2.post("finance/create/loan/prod", this.prefilledData)
        .then((x) => {
          window.fbq("track", eventName + "-success");
          this.$gtag.event(eventName + "-success", {
            event_category: "click-event",
            event_label: "create loan success",
            value: 1,
          });
          console.log(x);
          this.convert();
          if (x.data.applicationId) {
            this.applicationId = x.data.applicationId;
            this.triggerTimer();
            this.openTimerModel = true;
            // this.$router.push(`success/${x.data.applicationId}`);
          } else {
            window.fbq("track", eventName + "-failure");
            this.$gtag.event(eventName + "-failure", {
              event_category: "click-event",
              event_label: "create loan failure",
              value: 1,
            });
            this.$router.push("failure");
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$router.push("failure");
        });
    },
    findPropertyStatus(type) {
      return this.propertyStatusList.find((x) => {
        return x.abbr == type;
      });
    },
  },
  computed: {
    isButtonEnabled() {
      return this.enabled;
    },
  },
};
</script>
<style scoped></style>
